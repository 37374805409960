var site = site || {};
var generic = generic || {};
var Drupal = Drupal || {};
var Model = Model || {};

site.prodStoreCheck = site.prodStoreCheck || {};

(function ($) {
  /**
    * In-store locator class that handles the interfacing with the product pages.
    * Uses the in-store library classes to handle map and door calls along with the display.
    * Also handles common functionality used in different panels, like the location overlay.
    * Class needs to be loaded on page load using the event 'instore:product:init'.
    * When the locator is needed, you can display inline or in an overlay using the events
    *   'instore:product:overlay' or 'instore:product:inline'.
    * Event bindings placed in the bindEvents method are also available once it is instantiated.
  */
  site.inStoreLocator = function () {
    this.bodyClass = 'in-store-locator';
    this.eventNameSpace = 'inStoreLocator:';
  };

  /**
    * Class to set up various in-store locator css state classes.
    * Look to the site's css as reference.
  */
  site.inStoreLocator.prototype.states = {
    doorsShown: 'state-doors-shown',
    doorsShownInline: 'state-doors-shown-inline'
  };

  /**
    * Method to instantiate different classes variables when inStoreLocator is loaded.
    * Mainly used to make data is cleared out if locator is opened more than once.
  */
  site.inStoreLocator.prototype.setVars = function () {
    this.dom = {};
    this.dom.$inlineContainer = null;
    this.resultData = {};
    this.mergedDoorData = {};
    this.activeSku = null;
    this.containerOpen = true;
  };

  /**
    * Method to get all DOM selectors when class is initialized.
    * All selectors should be placed here to keep everything located in one spot.
  */
  site.inStoreLocator.prototype.getNodes = function () {
    this.dom.$overlayContainer = $('.js-instore-container');
    this.dom.$doorsContainer = $('.js-product-doors');
    this.dom.$locConfirmOverlay = $('.js-location-confirm');
    this.dom.$locConfirmAcceptBtn = $('.js-location-allow');
    this.dom.$locConfirmDeclineBtn = $('.js-location-decline');
    this.dom.$locConfirmCloseBtn = $('.js-location-confirm-close');
    this.dom.$closeLink = $('.close-link');
  };

  /**
    * Method to set all bind events when class is initialized.
    * All bind events, including events from other panels, should be placed here.
  */
  site.inStoreLocator.prototype.bindEvents = function () {
    $(document).on(this.eventNameSpace + 'openLocationOverlay', $.proxy(this.openLocationOverlay, this));
    $(document).on(this.eventNameSpace + 'closeLocationOverlay', $.proxy(this.closeLocationOverlay, this));
    $(document).on(this.eventNameSpace + 'addDoorsState', $.proxy(this.addDoorsState, this));
    $(document).on(this.eventNameSpace + 'addDoorsStateInline', $.proxy(this.addDoorsStateInline, this));
    $(document).on(this.eventNameSpace + 'reset', $.proxy(this.onReset, this));
    $(document).on(this.eventNameSpace + 'inserted', $.proxy(this.initLayout, this));
    $(document).on(this.eventNameSpace + 'removeEvents', $.proxy(this.removeEvents, this));

    $(document).on('product.skuSelect', $.proxy(this.onSkuChange, this));
    $(document).on('MPP:quickshopsDestroyed', $.proxy(this.onReset, this));
  };

  /**
    * Method to handle the display of the inventory locator button.
    * Checks the product data to see if it follows the display criteria set in the CMS.
    * If it passes, it will then check to see if doors are in the area.
    * If so, it will load the API and set buttons events defined in setButtonEvents.
  */
  site.inStoreLocator.prototype.setDisplay = function (e, args) {
    var that = this;
    var _args = args || {};

    if (!_args.$container || !_args.$cartBtn) {
      return null;
    }
    _args.prodData = _args.data || Model.PageData.get();
    var isValidProd = this.checkProdFlags(_args.prodData)
                      && this.checkProdCats(_args.prodData)
                      && this.checkProdIds(_args.prodData);

    if (isValidProd) {
      if (!this.called) {
        $(document).trigger('instore:product:checkAreaDoors', {
          prodData: _args.prodData,
          callback: function (hasAreaDoors) {
            if (hasAreaDoors) {
              // that.displayDoorsLink();
              $(document).trigger('instore:product:closeInline');
              $(document).trigger('instore:product:inline', {
              // // $(document).trigger('instore:product:close');
              // // $(document).trigger('instore:product:overlay', {
                prodData: _args.prodData,
                container: _args.$container,
                activeSku: _args.prodData.SKU_ID
              });
            }
          }
        });
        this.called = true;
      }
    }
  };

  site.inStoreLocator.prototype.displayDoorsLink = function (response) {
    // console.log(":::::::::::::::::::::::");
    // console.log(response);
  };

  /**
    * Method to set the inventory button click event depending on if it's for inline or overlay.
    * If a container node is passed, the inline events will be fired.
    * Otherwise, overlay events are fired instead.
    *
  */
  site.inStoreLocator.prototype.setInvButtonEvents = function (args) {
    var that = this;
    var _args = args || {};

    if (!_args.$invBtn) {
      return null;
    }
    _args.$invBtn.click(function (e) {
      e.preventDefault();
      if (that.containerOpen) {
        $(document).trigger('instore:product:close');
        $(document).trigger('instore:product:closeInline');
      } else {
        if (_args.$container) {
          $(document).trigger('instore:product:closeInline');
          $(document).trigger('instore:product:inline', {
            prodData: _args.prodData,
            container: _args.$container,
            activeSku: _args.$cartBtn.attr('data-skubaseid')
          });
        } else {
          $(document).trigger('instore:product:close');
          $(document).trigger('instore:product:overlay', {
            prodData: _args.prodData,
            activeSku: _args.$cartBtn.attr('data-skubaseid')
          });
        }
      }
    });
  };

  /**
    * Convience method to compare a product's MISC_FLAG to the MISC_FLAG set in the CMS.
    * The CMS adds the MISC_FLAGs to hide in the module array site.prodStoreCheck.hideBtnMiscFlags.
    * If the MISC_FLAG of the product matches any of the flags in the array, it will fail.
  */
  site.inStoreLocator.prototype.checkProdFlags = function (data) {
    if (!data || !site.prodStoreCheck.hideBtnMiscFlags) {
      return true;
    }
    var isValid = true;
    var flagsArray = site.prodStoreCheck.hideBtnMiscFlags;

    for (var x = 0; x < flagsArray.length; x++) {
      if (data.MISC_FLAG) {
        if (data.MISC_FLAG.toString() === flagsArray[x].toString()) {
          isValid = false;
        }
      }
    }

    return isValid;
  };

  /**
    * Convience method to compare a product's DEFAULT_CAT_ID to the hide_button_cats set in the CMS.
    * The CMS adds the hide_button_cats to hide in the module array site.prodStoreCheck.hideBtnCats.
    * If the hide_button_cats of the product matches any of the flags in the array, it will fail.
  */
  site.inStoreLocator.prototype.checkProdCats = function (data) {
    if (!data || !site.prodStoreCheck.hideBtnCats) {
      return true;
    }
    var isValid = true;
    var catsArray = site.prodStoreCheck.hideBtnCats.toString().split(',');

    for (var x = 0; x < catsArray.length; x++) {
      if (data.DEFAULT_CAT_ID.toString() === catsArray[x].toString().trim()) {
        isValid = false;
      }
    }

    return isValid;
  };

  /**
    * Convience method to compare a product's PRODUCT_ID to the PRODUCT_IDs set in the CMS.
    * The CMS adds the PRODUCT_IDs to hide in the module array site.prodStoreCheck.hideBtnProds.
    * If the PRODUCT_ID of the product matches any of the flags in the array, it will fail.
  */
  site.inStoreLocator.prototype.checkProdIds = function (data) {
    if (!data || !site.prodStoreCheck.hideBtnProds) {
      return true;
    }
    var isValid = true;
    var prodsArray = site.prodStoreCheck.hideBtnProds.toString().split(',');

    for (var x = 0; x < prodsArray.length; x++) {
      if (data.PRODUCT_ID.toString() === prodsArray[x].toString().trim()) {
        isValid = false;
      }
    }

    return isValid;
  };

  /**
    * Method to load all supporting libraries for the in-store locator.
    * Called before in-store locator is displayed.
  */
  site.inStoreLocator.prototype.init = function () {
    // Load supporting library files.

    this.StoreMap = new site.map();
    this.Product = new site.product();
    this.Controls = new site.controls();
    this.Display = new site.display();
    this.Doors = new site.doors();
  };

  /**
    * Method to display in-store locator in a generic overlay.
    * Calls helper methods to not only display the overlay but add the functionality.
    * After all methods are called, the 'inStoreLocator:launched' event is triggered.
  */
  site.inStoreLocator.prototype.launch = function (e, args) {
    var _args = args || {};

    this.init();
    this.setVars();
    this.launchOverlay();
    this.loadMap();
    this.getNodes();
    this.bindEvents();
    this.setCloseBtn();
    this.setLocationOverlay();
    this.addBodyClass();

    $(document).trigger(this.eventNameSpace + 'launched', _args);
  };

  /**
    * Method to display in-store locator in the page document.
    * Similar to .launch, it calls helper methods to not only display the overlay but add the functionality.
    * Only difference is _args.container is passed to define where the page templates are added to, which is defined
    *   when the product page is initialized.
    * After all methods are called, the 'inStoreLocator:opened' event is triggered.
  */
  site.inStoreLocator.prototype.inline = function (e, args) {
    var _args = args || {};

    // console.log(arguments);
    this.init();
    this.setVars(_args);
    this.bindEvents(_args);
    this.insert(_args);
  };

  /**
    * Method to close in-store locator in the page document.
    * Fires the panel close event which can also be used by other panels.
  */
  site.inStoreLocator.prototype.close = function (e, args) {
    $(document).trigger(this.eventNameSpace + 'close');
  };

  /**
    * Method to close in-store locator in the page document.
    * Fires the panel close event which can also be used by other panels.
  */
  site.inStoreLocator.prototype.closeInline = function (e, args) {
    $(document).trigger(this.eventNameSpace + 'close');
    if (this.dom && this.dom.$inlineContainer) {
      this.dom.$inlineContainer.html('');
    }
  };

  /**
    * Method to insert the in-store template to the pass $container DOM selector.
    * Used for the inline option of the in-store locator.
  */
  site.inStoreLocator.prototype.insert = function (args) {
    var _args = args || {};
    var that = this;

    if (site.templates && site.templates['product_store_check_layout_v1']) {
      site.template.get({
        name: 'product_store_check_layout_v1',
        data: {},
        callback: function (html) {
          // Need to filter out no visible container since mobile and pc templates are used together.
          that.dom.$inlineContainer = _args.container.filter(function () {
            return $(this).is(':visible');
          });
          that.dom.$inlineContainer.html(html);

          $(document).trigger(that.eventNameSpace + 'inserted', _args);
        }
      });
    }
  };

  /**
    * Method to initialize the inserted the in-store template.
    * Used for the inline option of the in-store locator.
  */
  site.inStoreLocator.prototype.initLayout = function (e, args) {
    var _args = args || {};

    this.getNodes();
    this.addBodyClass();

    $(document).trigger(this.eventNameSpace + 'opened', _args);
  };

  /**
    * Method that grabs the inline-template and places it in an overlay using generic.overlay.
  */
  site.inStoreLocator.prototype.launchOverlay = function () {
    var that = this;
    var _inlineTemplate = $("script.inline-template[path='product_store_check']").html();

    generic.overlay.launch({
      content: _inlineTemplate,
      includeBackground: false,
      // backgroundNodeClickHide: true,
      lockPosition: false,
      center: true, // one line required set
      cssClass: '',
      cssStyle: {
        width: '960px'
      }
    });
  };

  /**
    * Method that calls the map event 'map:render' to have the default map display after the overlay is displayed.
  */
  site.inStoreLocator.prototype.loadMap = function () {
    $(document).trigger('map:render', {
      zoom: 4
    });
  };

  /**
  * Method that calls Model.MapApi to make sure country data and the google api are loaded.
  * Called by using the event 'instore:product:loadApi'.
  * Should be called before the locator is displayed inline or in an overlay.
  */
  site.inStoreLocator.prototype.loadApi = function (e, args) {
    if (!Model.MapApi) {
      return null;
    }
    Model.MapApi.init();
  };

  /**
  * Method that checks if there are any doors in your area.
  * It uses Model.DoorsInventory.loadDoorsOnly to do a door only query.
  * It will return a boolean if that query results in any returned doors.
  */
  site.inStoreLocator.prototype.checkAreaDoors = function (product, e, args) {
    if (!Model.DoorsInventory) {
      return null;
    }
    var _args = args || {};
    var hasDoorsInArea = false;
    var _skus = null;

    if (product && product.data && product.data.SKU_ID) {
      _skus = product.data.SKU_ID;
    }
    Model.DoorsInventory.loadDoorsOnly({
      skus: _skus,
      callback: function (result) {
        if (_args.callback) {
          if (result.count > 0) {
            hasDoorsInArea = true;
          }
          args.callback(hasDoorsInArea);
        }
      }
    });
  };

  /**
    * Method that handles changes when the product sku select is changed in another panel class.
    * When the sku is changed, it sets the internal variable this.activeSku with the skuId.
    * And then triggers the re-rendering of the doors and map display with the new sku.
  */
  site.inStoreLocator.prototype.onSkuChange = function (e, skuId) {
    this.activeSku = skuId;
    $(document).trigger(this.eventNameSpace + 'reset');
  };

  /**
    * Method that sets the location overlay permission functionality in the in-store locator.
    * When enabled, the Get Location button triggers an additional overlay that
    *   gives the user the chance to prevent location detection.
    * The allow and decline buttons are also set here, which use helper methods to do the locating or just hide the overlay.
  */
  site.inStoreLocator.prototype.setLocationOverlay = function () {
    var that = this;
    var $locConfirmNode = that.dom.$locConfirmOverlay;

    if (!$locConfirmNode) {
      return null;
    }

    that.dom.$locConfirmAcceptBtn.click(function (e) {
      e.preventDefault();
      var that = this;

      that.dom.$overlayContainer.trigger(that.eventNameSpace + 'getLocation', function () {
        that.dom.$overlayContainer.trigger(that.eventNameSpace + 'closeLocationOverlay');
      });
    });

    that.dom.$locConfirmDeclineBtn.click(function (e) {
      e.preventDefault();

      this.dom.$overlayContainer.trigger(this.eventNameSpace + 'closeLocationOverlay');
    });

    that.dom.$locConfirmCloseBtn.click(function (e) {
      e.preventDefault();

      this.dom.$overlayContainer.trigger(this.eventNameSpace + 'closeLocationOverlay');
    });
  };

  /**
    * Method to add the 'inStoreLocator:close' event to the overlay close btn.
  */
  site.inStoreLocator.prototype.setCloseBtn = function () {
    var that = this;

    that.dom.$closeLink.click(function (e) {
      e.preventDefault();
      $(document).trigger(that.eventNameSpace + 'close');
    });
  };

  /**
    * Method to handle all close events for the in-store locator.
    * Will be called if the event 'inStoreLocator:close' is fired.
  */
  site.inStoreLocator.prototype.onReset = function () {
    this.containerOpen = false;
    this.removeEvents();
    this.removeBodyClass();
  };

  /**
    * Method to unbind all events associated to document.
    * This will iterate through all docuemnt events removing all events with the panel event name space.
    * Defaults to the current evenetNameSpace but you can pass other namespaces in other panels too.
  */
  site.inStoreLocator.prototype.removeEvents = function (e, args) {
    var _args = args || {};
    var nameSpace = _args.nameSpace ? _args.nameSpace : this.eventNameSpace;
    var docEvents = $(document).data('events');

    for (var evt in docEvents) {
      var match = evt.match('^' + nameSpace);

      if (match) {
        $(document).unbind(match.input);
      }
    }
  };

  /**
    * Method to open the location confirm window.
    * Uses the css class active to display the overlay.
    * Also can be used with the even't 'inStoreLocator:openLocationOverlay'.
  */
  site.inStoreLocator.prototype.openLocationOverlay = function () {
    var $locConfirmNode = this.dom.$locConfirmOverlay;

    if (!$locConfirmNode) {
      return null;
    }
    $locConfirmNode.addClass('active');
  };

  /**
    * Method to close the location confirm window.
    * Removes the css class active to hide the overlay.
    * Also can be used with the even't 'inStoreLocator:closeLocationOverlay'.
  */
  site.inStoreLocator.prototype.closeLocationOverlay = function () {
    var $locConfirmNode = this.dom.$locConfirmOverlay;

    if (!$locConfirmNode) {
      return null;
    }
    $locConfirmNode.removeClass('active');
  };

  /**
    * Method to handle adding the doorsShown state to the in-store locator overlay.
    * Uses the css class defined in this.states to control showing the door rows when a search is done.
    * Also can be used with the even't 'inStoreLocator:addDoorsState'.
  */
  site.inStoreLocator.prototype.addDoorsState = function () {
    this.dom.$overlayContainer.addClass(this.states.doorsShown);
  };

  /**
    * Method to handle adding the doorsShown state to the inline in-store locator.
    * Similar to addDoorsState, but uses a class specific to having the locator be inline.
    * Also can be used with the even't 'inStoreLocator:addDoorsStateInline'.
  */
  site.inStoreLocator.prototype.addDoorsStateInline = function () {
    this.dom.$overlayContainer.addClass(this.states.doorsShownInline);
  };

  /**
    * Method to add the defined locator body class to the DOM.
    * Used if any css styling is needed outside of the locator.
  */
  site.inStoreLocator.prototype.addBodyClass = function () {
    $('body').addClass(this.bodyClass);
  };

  /**
    * Method to remove the defined locator body class to the DOM.
  */
  site.inStoreLocator.prototype.removeBodyClass = function () {
    $('body').removeClass(this.bodyClass);
  };

  /**
    * Method to define the event triggers that are used by the product pages to display the locator.
    * Used on page load and when the module is destroyed.
  */
  site.inStoreLocator.prototype.load = function (product, $product) {
    $(document).on('instore:product:setDisplay', $.proxy(Drupal.behaviors.inStoreLocator.setDisplay, this));
    $(document).on('instore:product:loadApi', $.proxy(Drupal.behaviors.inStoreLocator.loadApi, Drupal.behaviors.inStoreLocator));
    $(document).on('instore:product:overlay', $.proxy(Drupal.behaviors.inStoreLocator.launch, Drupal.behaviors.inStoreLocator));
    $(document).on('instore:product:inline', $.proxy(Drupal.behaviors.inStoreLocator.inline, Drupal.behaviors.inStoreLocator));
    $(document).on('instore:product:close', $.proxy(Drupal.behaviors.inStoreLocator.close, Drupal.behaviors.inStoreLocator));
    $(document).on('instore:product:closeInline', $.proxy(Drupal.behaviors.inStoreLocator.closeInline, Drupal.behaviors.inStoreLocator));
    $(document).on('instore:product:checkAreaDoors', $.proxy(Drupal.behaviors.inStoreLocator.checkAreaDoors, Drupal.behaviors.inStoreLocator, product));
    $product.trigger('instore:loaded', product);
  };

  /**
    * Instantiation of the Drupal inStoreLocator.
  */
  Drupal.behaviors.inStoreLocator = new site.inStoreLocator();

  // /**
  //   * Definied event triggers that are used by the product pages to display the locator.
  // */
  // $(document).on('product:render_sku', function() {
  //   Drupal.behaviors.inStoreLocator.load();
  // });
})(jQuery);
